import React from 'react';

import { JobApplyForm } from '../../components/job-apply-form-modal';
import SEO from '../../components/seo';
import styles from './job.module.css';

// import jobBgSrc from "../../images/job-bg.png"
const JobPage = () => {
  return (
    <>
      <SEO title="Kontrolor kvality" />

      <div className={styles.bgJob}>
        <div className={styles.job}>
          <h1 className={styles.textHeader}>Kontrolor kvality</h1>

          {/* <h3 className={styles.textSubHeader}>
            Do našeho týmu hledáme šikovného/ou kolegu/yni na pozici Technologa potravinářské výroby. Máte praxi v potravinářském průmyslu? Baví Vás technologie výrobních procesů? Tak jste tady správně!
          </h3> */}
          <p className={styles.text}>
            NutritionPro je jedním z nejrychleji rostoucích Foodtech startupů v ČR (500 %+ meziroční růst)
          </p>

          <p className={styles.text}>
            Přišli jsme na trh s námi vyvinutým algoritmem, který na základě údajů o Tvém těle je schopen vytvořit jídelníček přímo Tobě na míru. Tím to ale nekončí – jídlo pro Tebe uvaříme a dovezeme až ke dveřím!
          </p>

          <p className={styles.text}>
            <a href="https://forbes.cz/miliardar-janecek-veri-budoucnosti-zdraveho-jidla-do-startupu-nutritionpro-investuje-spolu-s-dalsimi-35-milionu/" target="_blank">Nedávno jsme obdrželi investici</a> pro další expanze, a proto hledáme talentovaného Kontrolora kvality.
          </p>

          <p className={styles.text}>
            Odvádíme kvalitní a smysluplnou práci, která vede ke spokojenosti všech našich klientů. Jsme tým mladých lidí, který k sobě hledá nové kolegy s podobným „mindsetem“. Pokud to máš stejně, tak ti zaručíme tři věci: stabilitu, potenciál růstu a práci s unikátním produktem.
          </p>

          <p className={styles.text}>
            Jestli chceš být součástí nadšeného týmu a rozšířit supercrew úspěšného startupu, jsi perfekcionista a nechceš si nechat ujít šanci a příležitost skočit do nejžhavějšího startupového odvětví, FoodTech, tak čti dál!
          </p>

          <h3 className={styles.textSubHeader}>
            Co u nás budeš dělat?
          </h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
            Budeš mít na starosti kontrolu 4 nejdůležitých etap - kontrolu kvality vstupních surovin, kontrolu správnosti dodržování technologických postupů, senzorickou kontrolu výrobku - chuti a vzhledu.
            </li>
            <li className={styles.newList}>
            Zajišťovat aby pokrmy, které byly připravené ve výrobě odpovídaly standardům stanoveným firmou.
            </li>
            <li className={styles.newList}>
            Po vyhodnocení procesů - připravovat a předávat reporting výrobnímu řediteli.
            </li>
            <li className={styles.newList}>
            Tvořit a spravovat příslušnou kontrolní dokumentaci.
            </li>
            <li className={styles.newList}>
            Zajišťovat provádění interních auditů v kuchyních.
            </li>
            <li className={styles.newList}>
            Proaktivně navrhovat procesy na zlepšení kontroly kvality.
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>
          Co od tebe očekáváme?
          </h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
            Jsi perfekcionista, který neodpustí ani tu nejmenší chybu a všechno musí být naprosto dokonalé a běžet jako po drátkách.
            </li>
            <li className={styles.newList}>
            Řídíš se pravidlem „Je lepší nepředávat objednávku zákazníkům vůbec, než předat špatnou“.
            </li>
            <li className={styles.newList}>
            SŠ/VŠ vzdělání v oboru potravinářství.
            </li>
            <li className={styles.newList}>
            Min. 2 roky praxe na podobné pozici.
            </li>
            <li className={styles.newList}>
            Samostatnost, iniciativní přístup, smysl pro pečlivost.
            </li>
            <li className={styles.newList}>
            Znalost hygienických pravidel a systémů HACCP.
            </li>
            <li className={styles.newList}>
            Znalost procesů a nástrojů kvality.
            </li>
            <li className={styles.newList}>
            Cit pro detail.
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>Co získáš od nás?</h3>

          <ul className={styles.textUl}>
            <li className={styles.newList}>
            Motivační finanční ohodnocení a měsíční bonus
            </li>
            <li className={styles.newList}>Plně hrazené vzdělávací kurzy a konzultace s předními odborníky v oboru</li>
            <li className={styles.newList}>Firemní obědy zdarma</li>
            <li className={styles.newList}>Multisport kartu plně hrazenou firmou</li>
            <li className={styles.newList}>
            Vodu, kávu, čaj, džusy, čerstvé ovoce na pracovišti dle libosti
            </li>
            <li className={styles.newList}>Sick-days</li>
          </ul>

          <p className={styles.text}>
          Sídlíme v moderních kancelářích v srdci Karlína, takže jako bonus tě čeká i skvělé prostředí pro networking, neformální atmosféra bez dress code a velká terasa s výhledem na Prahu!
          </p>
          <p className={styles.text}>
            <b>
            Těšíme se na tvoje CV a rádi tě uvítáme v našem týmu NutritionPro!
            </b>
          </p>

          <h3 className={styles.textSubHeader}>Kontakty:</h3>
          <a className={styles.jobsEmail} href="mailto:jobs@nutritionpro.cz">
            jobs@nutritionpro.cz
          </a>
          <br />
          {/* <a className={styles.jobsEmail} href="tel:+420608068463">
            +420 608 068 463
          </a> */}
          <br />
          <br />
          <a className={styles.backButton} href="/jobs">
            Zpět
          </a>
          {/* <div style={{ marginTop: "40px" }}>
            <JobApplyForm formName="JobApplyForm_productionManager" />
          </div> */}
        </div>
      </div>
    </>
  )
}

export default JobPage
